import crypto from 'crypto';
import { range } from 'lodash';

export function filterAndMap<T, U>(
    array: T[],
    filterFunction: (val: T, index: number) => boolean,
    mapFunction: (val: T, index: number) => U,
): U[] {
    return array.reduce<U[]>((acc, val, index) => {
        if (filterFunction(val, index)) {
            return [...acc, mapFunction(val, index)];
        }
        return acc;
    }, []);
}

export function hashString(str: string): string {
    return crypto.createHash('sha256').update(str).digest('hex');
}

export function obfuscateEmail(email: string): string {
    try {
        const [name, domain] = email.split('@');

        const nameAsterisks = range(name.length - 2)
            .map(() => '*')
            .join('');
        const domainAsterisks = range(domain.length - 2)
            .map(() => '*')
            .join('');

        return `${name.substring(0, 2)}${nameAsterisks}@${domain.substring(0, 2)}${domainAsterisks}`;
    } catch (err) {
        throw new Error('Invalid email format ' + email);
    }
}

export function serialize<T>(object: unknown): T {
    return JSON.parse(
        JSON.stringify(object, (_, value) => (typeof value === 'bigint' ? parseInt(value.toString(), 10) : value)),
    );
}

export function humanReadableNumber(num: number) {
    if (num < 1_000) {
        return num;
    }

    let divider = 1_000;
    let symbol = 'k';
    if (num >= 1_000_000) {
        divider = 1_000_000;
        symbol = 'M';
    }
    const result = num / divider;

    const [int, dec] = result.toFixed(2).split('.');
    const rest = Math.floor(parseInt(dec, 10) / 10);

    return `${rest > 0 ? `${int}.${rest}` : int}${symbol}`;
}

export function shiftItems(list: Array<{ sort: number }>, index: number, newIndex: number) {
    let newColumns = [...list];

    const [column] = newColumns.splice(index, 1);
    const before = newColumns.slice(0, newIndex);
    const after = newColumns.slice(newIndex);

    newColumns = [...before, column, ...after];
    newColumns.forEach((column, i) => {
        column.sort = i;
    });

    return newColumns;
}
