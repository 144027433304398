import { useToast } from '@chakra-ui/react';
import { Like } from '@prisma/client';
import axios from 'axios';
import { noop } from 'lodash';
import { signIn, useSession } from 'next-auth/react';
import { useCallback, useMemo, useState } from 'react';
import { RiHeartFill, RiHeartLine } from 'react-icons/ri';

export default function useLike(roadmapId: string, isLiked = false, callback: (like: Like | null) => void = noop) {
    const icon = useMemo(() => (isLiked ? RiHeartFill : RiHeartLine), [isLiked]);

    const [isLiking, setLiking] = useState(false);

    const { data: session } = useSession();
    const toast = useToast();

    const onLike = useCallback(async () => {
        if (!session) {
            return signIn();
        }

        try {
            setLiking(true);
            const response = await axios.put(`/api/roadmaps/${roadmapId}/like`);
            callback(response.data.like);
        } catch (error) {
            toast({
                title: 'Could not like roadmap',
                description: (error as Error).message,
                isClosable: true,
                duration: 9000,
            });
        } finally {
            setLiking(false);
        }
    }, [callback, roadmapId, session, toast]);

    const value = useMemo(
        () => ({
            Icon: icon,
            isLiking,
            onLike,
        }),
        [icon, isLiking, onLike],
    );

    return value;
}
