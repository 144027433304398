import { Flex, IconButton, Text } from '@chakra-ui/react';
import { Like } from '@prisma/client';
import useLike from './useLike';

type LikeIconProps = {
    isLiked?: boolean;
    likes?: number;
    roadmapId: string;
    onLike: (like: Like | null) => void;
    hideNumber?: boolean;
};

export default function LikeIcon({
    isLiked = false,
    likes = 0,
    roadmapId,
    hideNumber = false,
    onLike: callback,
}: LikeIconProps) {
    const { Icon, isLiking, onLike } = useLike(roadmapId, isLiked, callback);

    return (
        <Flex align="center">
            <IconButton
                aria-label={isLiked ? 'Unlike' : 'Like'}
                icon={<Icon />}
                size="xs"
                fontSize="lg"
                mr={1}
                onClick={onLike}
                bgColor="transparent"
                isLoading={isLiking}
            />
            {!hideNumber && <Text fontSize="sm">{likes}</Text>}
        </Flex>
    );
}
