import { CopyIcon, EditIcon } from '@chakra-ui/icons';
import {
    Box,
    Flex,
    Heading,
    Text,
    Avatar,
    useColorModeValue,
    useDisclosure,
    useToast,
    Tooltip,
    IconButton,
    Image,
} from '@chakra-ui/react';
import { Like } from '@prisma/client';
import axios from 'axios';
import { formatLocalDateForAPI } from 'lib/utils/date';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { inflect } from 'inflection';
import CopyButton from '../CopyButton';
import { noop } from 'lodash';
import TagList from 'components/Tag/TagList';
import LikeIcon from 'components/Like/LikeIcon';
import { humanReadableNumber } from 'lib/utils/functions';
import { getFontColorForBackground } from 'lib/colorHelper';
import RoadmapPlaceholder from './RoadmapPlaceholder';
import { PublicRoadmap } from 'lib/types';

type PublicRoadmapCardProps = {
    roadmap: PublicRoadmap;
    categoryFilter?: string | null;
    canEdit?: boolean;
    onLike?: (roadmap: PublicRoadmap, like: Like | null) => void;
};

export default function PublicRoadmapCard({
    roadmap,
    categoryFilter,
    canEdit = false,
    onLike = noop,
}: PublicRoadmapCardProps) {
    const [isHovering, setHovering] = useState(false);
    const [isCopying, setCopying] = useState(false);

    const { data: session } = useSession();

    const version = useMemo(() => roadmap.currentVersion, [roadmap.currentVersion]);

    const { isOpen, onClose, onOpen } = useDisclosure();

    const router = useRouter();
    const toast = useToast();

    const isLiked = useMemo(() => roadmap.likes.some((like) => like.userId === session?.userId), [roadmap, session]);

    const borderColor = useColorModeValue('gray.200', 'gray.800');

    const onCopy = async (date: Date, mode: string) => {
        try {
            setCopying(true);
            const response = await axios.post(`/api/roadmaps/${roadmap.id}/copy`, {
                mode,
                base: formatLocalDateForAPI(date),
                draft: false,
            });
            router.push(`/${response.data.roadmapId}/edit`);
        } catch (error) {
            toast({
                title: 'Could not copy roadmap',
                description: (error as Error).message,
                isClosable: true,
                duration: 9000,
            });
        } finally {
            setCopying(false);
        }
    };

    if (!version) {
        return null;
    }

    const imageOrGradient = version.backgroundImage
        ? {
              bgImage: `url(${version.backgroundImage}&w=320&h=120&crop=focalpoint&fit=crop)`,
          }
        : {
              bgGradient: `linear(to-br, ${version.gradientStart}, ${version.gradientEnd})`,
          };

    const selectedCategory =
        (categoryFilter && version?.categories.find(({ id }) => id === categoryFilter)) || version?.categories[0];

    const queryParamSeparator = version.screenshotPath?.includes('?') ? '&' : '?';

    return (
        <Box w="320px">
            <Flex
                position="relative"
                bgColor="white"
                height="150px"
                rounded="md"
                mb={2}
                border="1px"
                borderColor={borderColor}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                {version.screenshotPath ? (
                    <Image
                        rounded="md"
                        src={`${version.screenshotPath}${queryParamSeparator}ts=${new Date().getTime()}`}
                        width="320px"
                        height="150px"
                        alt={version.title}
                    />
                ) : (
                    <>
                        <Flex width="100%" height="120px" rounded="md" borderBottomRadius={0} {...imageOrGradient} />
                        <Flex
                            position="absolute"
                            width="100%"
                            height="150px"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <CopyIcon
                                position="absolute"
                                top={2}
                                right={2}
                                color={getFontColorForBackground(version.gradientEnd || '')}
                            />
                            <Flex mb={2} flex="1" alignItems="center">
                                <Heading
                                    as="h3"
                                    size="sm"
                                    color={version.titleColor || getFontColorForBackground(version.gradientStart || '')}
                                    textAlign="center"
                                    py={2}
                                    px={8}
                                    maxH="45px"
                                    overflow="hidden"
                                >
                                    {version.title}
                                </Heading>
                            </Flex>

                            <Flex>
                                <RoadmapPlaceholder />
                            </Flex>
                        </Flex>
                    </>
                )}

                <Flex
                    width="100%"
                    height="75px"
                    bgGradient={`linear(to-t, gray.700, transparent)`}
                    rounded="md"
                    position="absolute"
                    bottom="-2px"
                />
                <Flex width="100%" position="absolute" bottom={1} px={2} justify="flex-end">
                    <Text color="white" fontSize="xs">
                        {selectedCategory ? `${selectedCategory.name} · ` : ''}
                        {humanReadableNumber(roadmap.views as unknown as number)}{' '}
                        {inflect('view', roadmap.views as unknown as number)}
                    </Text>
                </Flex>
                {(isHovering || isOpen) && (
                    <Flex
                        position="absolute"
                        width="100%"
                        height="150px"
                        alignItems="center"
                        justifyContent="center"
                        bgColor="blackAlpha.600"
                        rounded="md"
                        cursor="pointer"
                        onClick={() => !isOpen && router.push(`/${roadmap.id}`)}
                    >
                        <Flex
                            position="absolute"
                            top="2"
                            width="100%"
                            px="2"
                            justifyContent="flex-end"
                            align="center"
                            gap={1}
                        >
                            {canEdit && (
                                <Tooltip label="Edit roadmap" placement="top">
                                    <IconButton
                                        aria-label="Edit roadmap"
                                        icon={<EditIcon />}
                                        size="sm"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            router.push(`/${roadmap.id}/edit`);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <CopyButton
                                size="sm"
                                isCopying={isCopying}
                                isOpen={isOpen}
                                onClose={onClose}
                                onOpen={onOpen}
                                onClone={onCopy}
                            />
                        </Flex>
                        <Heading as="h3" color="white">
                            VIEW
                        </Heading>
                        <TagList tags={version.tags} width="100%" position="absolute" bottom={2} px={2} />
                    </Flex>
                )}
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="sm" flex="1" isTruncated mr={4}>
                    {version.title}
                </Text>
                <Tooltip label={roadmap.owner.name}>
                    <Avatar size="xs" mr={2} src={roadmap.owner.image || ''} name={roadmap.owner.name || ''} />
                </Tooltip>
                <LikeIcon
                    isLiked={isLiked}
                    onLike={(like) => onLike(roadmap, like)}
                    roadmapId={roadmap.id}
                    likes={roadmap.likes.length}
                />
            </Flex>
        </Box>
    );
}
